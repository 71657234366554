<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="入库单id" >
                <el-input v-model="search.ruKuDanId" size="small" />
            </x-search-item>
            <x-search-item label="入库类型" >
              <!--  <x-selector-one v-model="search.ruKuLX" size="small" dictType="T_RU_KU_L_X"/>-->
                <el-select v-model="search.ruKuLX" placeholder="请选择入库类型" size="small">
                    <el-option :label="rkLX.SYLX.name" :value="rkLX.SYLX.key"></el-option>
                    <el-option :label="rkLX.CSHRK.name" :value="rkLX.CSHRK.key"></el-option>
                    <el-option :label="rkLX.CGRK.name" :value="rkLX.CGRK.key"></el-option>
                    <el-option :label="rkLX.BHRK.name" :value="rkLX.BHRK.key"></el-option>
                    <el-option v-if="showOption" :label="rkLX.DBRK.name" :value="rkLX.DBRK.key"></el-option>
                    <el-option :label="rkLX.PYRK.name" :value="rkLX.PYRK.key"></el-option>
                    <el-option v-if="showOption" :label="rkLX.SQDBRK.name" :value="rkLX.SQDBRK.key"></el-option>
                    <el-option :label="rkLX.JSRK.name" :value="rkLX.JSRK.key"></el-option>
                    <el-option v-if="showOption" :label="rkLX.SHDBRK.name" :value="rkLX.SHDBRK.key"></el-option>
                    <el-option v-if="showOption" :label="rkLX.MDDBRK.name" :value="rkLX.MDDBRK.key"></el-option>
                    <el-option v-if="!showOption" :label="rkLX.MDTHRK.name" :value="rkLX.MDTHRK.key"></el-option>
                    <el-option :label="rkLX.SYTHRK.name" :value="rkLX.SYTHRK.key"></el-option>
                    <el-option :label="rkLX.QTRK.name" :value="rkLX.QTRK.key"></el-option>
                </el-select>
            </x-search-item>
            <x-search-item label="商品名称" >
                <el-input v-model="search.shangPinMC" size="small" />
            </x-search-item>
            <x-search-item label="商品id" >
                <el-input v-model="search.shangPinId" size="small" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
<!--        <div class="x-page-btn">-->
<!--&lt;!&ndash;            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>&ndash;&gt;-->
<!--            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDeleteBatch">-->
<!--                <el-button type="danger" size="mini" round slot="reference">批量删除</el-button>-->
<!--            </el-popconfirm>-->
<!--        </div>-->
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange">
            <el-table-column  label="序号" type="index" />
            <el-table-column  prop="shangPinMC" label="商品名称" />
            <el-table-column prop="shangPinHuoHao" label="货号" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{scope.row.shiFuDuoGuiGe=="true"?scope.row.guiGeHuoHao:scope.row.shangPinHuoHao}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="shangPinTiaoMa" label="条码" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{scope.row.shiFuDuoGuiGe=="true"?scope.row.guiGeTiaoMa:scope.row.shangPinTiaoMa}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="shangPinJinJia" label="商品进价" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{scope.row.shiFuDuoGuiGe=="true"?scope.row.guiGeShangPinJinJia:scope.row.shangPinJinJia}}</span>
                </template>
            </el-table-column>
            <el-table-column  prop="ruKuLX" label="入库类型" >
                <x-dict-show slot-scope="{row}" :code="row.ruKuLX" dictType="T_RU_KU_L_X" />
            </el-table-column>
            <el-table-column  prop="ruKuSJ" label="入库时间" show-overflow-tooltip/>
            <el-table-column  prop="ruKuSL" label="入库数量" show-overflow-tooltip/>
            <el-table-column  prop="ruKuJinE" label="入库金额" show-overflow-tooltip/>
            <el-table-column  prop="shangPinDW" label="商品单位" show-overflow-tooltip/>
            <el-table-column  prop="shengChanRQ" label="生产日期" show-overflow-tooltip/>
            <el-table-column  prop="baoZhiQiTian" label="保质期（天）" show-overflow-tooltip/>
            <el-table-column  prop="shiFuDuoGuiGe" label="是否多规格"show-overflow-tooltip >
                <x-dict-show slot-scope="{row}" :code="row.shiFuDuoGuiGe" dictType="T_YES_OR_NO" />
            </el-table-column>
<!--            <el-table-column width="180" prop="shiFuShanChu" label="是否删除" />-->
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
<!--                    <el-button type="primary" size="mini" round @click="handleUpdate(row.id)">编辑</el-button>-->
<!--                    <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDelete(row.id)">-->
<!--                        <el-button type="danger" size="mini" round slot="reference">删除</el-button>-->
<!--                    </el-popconfirm>-->
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/kcgl/RuKuJiLu";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/kcgl/rkjl/RuKuJiLuEdit";
    import Detail from "@/view/kcgl/rkjl/RuKuJiLuDetail";
    import {TYPE_RU_KU_L_X,DICT_TYPE_USER_ORGAN_LX} from "@/util/constant"

    export default {
        name: "RuKuJiLuList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                rkLX: TYPE_RU_KU_L_X,
                search: {
                    ruKuDanId: '',
                    ruKuLX: '',
                    shangPinMC: '',
                    shangPinId: '',
                }
            }
        },
        computed: {
            showOption() {
                // 门店为true
                return this.$store.getters.user.userOrganLx===DICT_TYPE_USER_ORGAN_LX.MD.key;
            },
        }
    }
</script>

<style scoped>
</style>
